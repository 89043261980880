@import '../../style/AppStyles';

@font-face {
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  src: url('../../style/theme/icons/icons.woff?ycb8li') format('woff');
}
@font-face {
  font-family: 'icons';
  src: url('../../style/theme/icons/custom_icons.woff?65393557') format('woff');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-transform: none;

  &.icon-smallest {
    font-size: 0.5rem;
  }

  &.icon-smaller {
    font-size: 0.6rem;
  }

  &.icon-small {
    font-size: 0.7rem;
  }

  &.icon-normal {
    font-size: 0.8rem;
  }

  &.icon-large {
    font-size: 1.2rem;
  }

  &.icon-larger {
    font-size: 1.8rem;
  }

  &.icon-largest {
    font-size: 2.5rem;
  }
}

.icon-decrease-arrow::before {
  content: '\e9c4';
}

.icon-add-customer::before {
  content: '\e9be';
}

.icon-shield-hand-2::before {
  content: '\e9bd';
}

.icon-arrow-down::before {
  content: '\e9b7';
}

.icon-arrow-left::before {
  content: '\e9b8';
}

.icon-arrow-right::before {
  content: '\e9b9';
}

.icon-arrow-up::before {
  content: '\e9ba';
}

.icon-branch::before {
  content: '\e9bb';
}

.icon-unknown-buyer::before {
  content: '\e9bc';
}

.icon-magnifier::before {
  content: '\e9b6';
}

.icon-collapse-thin::before {
  content: '\e9ae';
}

.icon-dropdown-thin::before {
  content: '\e9af';
}

.icon-external-link::before {
  content: '\e9b0';
}

.icon-external-search::before {
  content: '\e9bf';
}

.icon-greater-than-thin::before {
  content: '\e9b1';
}

.icon-hello::before {
  content: '\e9b2';
}

.icon-less-than-thin::before {
  content: '\e9b3';
}

.icon-logout::before {
  content: '\e9b4';
}

.icon-upload::before {
  content: '\e9b5';
}

.icon-interrogation-point::before {
  content: '\e9ad';
}

.icon-collapse::before {
  content: '\e98a';
}

.icon-less-than::before {
  content: '\e9ac';
}

.icon-greater-than::before {
  content: '\e989';
}

.icon-burger-menu::before {
  content: '\e964';
}

.icon-company::before {
  content: '\e963';
}

.icon-money-suitcase::before {
  content: '\e958';
}

.icon-check-2::before {
  content: '\e91d';
}

.icon-shield-hand::before {
  content: '\e91e';
}

.icon-car-small::before {
  content: '\e920';
}

.icon-caravan::before {
  content: '\e921';
}

.icon-info::before {
  content: '\e926';
}

.icon-alert::before {
  content: '\e900';
}

.icon-animal-cat::before {
  content: '\e901';
}

.icon-animal-dog::before {
  content: '\e902';
}

.icon-animal-horse::before {
  content: '\e903';
}

.icon-animal-hunting-deer::before {
  content: '\e904';
}

.icon-animal-paw::before {
  content: '\e905';
}

.icon-arrow-direction-path::before {
  content: '\e906';
}

.icon-at-sign::before {
  content: '\e907';
}

.icon-a-z::before {
  content: '\e908';
}

.icon-balance-scale::before {
  content: '\e909';
}

.icon-bank-monument-info::before {
  content: '\e90a';
}

.icon-battery::before {
  content: '\e90b';
}

.icon-bed::before {
  content: '\e90c';
}

.icon-bike::before {
  content: '\e90d';
}

.icon-board-paper-note::before {
  content: '\e90e';
}

.icon-book::before {
  content: '\e90f';
}

.icon-brain::before {
  content: '\e910';
}

.icon-briefcase::before {
  content: '\e9c1';
}

.icon-expand-left::before {
  content: '\e9c2';
}

.icon-broken-glass::before {
  content: '\e911';
}

.icon-broken-window::before {
  content: '\e912';
}

.icon-broom-clean::before {
  content: '\e913';
}

.icon-bubble::before {
  content: '\e9c3';
}

.icon-buggy::before {
  content: '\e914';
}

.icon-building::before {
  content: '\e915';
}

.icon-bullhorn::before {
  content: '\e916';
}

.icon-calculator::before {
  content: '\e917';
}

.icon-calendar::before {
  content: '\e918';
}

.icon-camera::before {
  content: '\e919';
}

.icon-candle::before {
  content: '\e91a';
}

.icon-car::before {
  content: '\e91b';
}

.icon-car-accident::before {
  content: '\e91c';
}

.icon-car-front::before {
  content: '\e91f';
}

.icon-car-tacho-fast::before {
  content: '\e922';
}

.icon-car-trailer::before {
  content: '\e923';
}

.icon-certificate::before {
  content: '\e924';
}

.icon-check::before {
  content: '\e925';
}

.icon-check-paper::before {
  content: '\e927';
}

.icon-city::before {
  content: '\e928';
}

.icon-cleanup-trash::before {
  content: '\e929';
}

.icon-clock::before {
  content: '\e92a';
}

.icon-cloud::before {
  content: '\e92b';
}

.icon-cog-wheel::before {
  content: '\e92c';
}

.icon-cog-wheels::before {
  content: '\e92d';
}

.icon-coins-money::before {
  content: '\e92e';
}

.icon-communication::before {
  content: '\e92f';
}

.icon-computer::before {
  content: '\e930';
}

.icon-couch::before {
  content: '\e931';
}

.icon-credit-card::before {
  content: '\e932';
}

.icon-cross::before {
  content: '\e933';
}

.icon-cross-2::before {
  content: '\e934';
}

.icon-cross-triangle::before {
  content: '\e935';
}

.icon-danger::before {
  content: '\e936';
}

.icon-data-exchange::before {
  content: '\e937';
}

.icon-diversity::before {
  content: '\e938';
}

.icon-dollar::before {
  content: '\e939';
}

.icon-door::before {
  content: '\e93a';
}

.icon-dropdown::before {
  content: '\e93b';
}

.icon-electric-plug::before {
  content: '\e93c';
}

.icon-excavator::before {
  content: '\e93d';
}

.icon-expensive-objects-jewelery::before {
  content: '\e93e';
}

.icon-eye-overview-sight::before {
  content: '\e93f';
}

.icon-family::before {
  content: '\e940';
}

.icon-folder::before {
  content: '\e941';
}

.icon-guide-navigation::before {
  content: '\e942';
}

.icon-hand-click-info::before {
  content: '\e943';
}

.icon-hand-finger-pointing::before {
  content: '\e944';
}

.icon-hand-get-money::before {
  content: '\e945';
}

.icon-hand-thumbs-up::before {
  content: '\e946';
}

.icon-headset-support::before {
  content: '\e947';
}

.icon-health-bag-doctor::before {
  content: '\e948';
}

.icon-health-stethoscope::before {
  content: '\e949';
}

.icon-heart::before {
  content: '\e94a';
}

.icon-heart-employee-engagement::before {
  content: '\e94b';
}

.icon-helmet::before {
  content: '\e94c';
}

.icon-help::before {
  content: '\e94d';
}

.icon-home-office::before {
  content: '\e94e';
}

.icon-hourglass::before {
  content: '\e94f';
}

.icon-house::before {
  content: '\e950';
}

.icon-house-broken-window::before {
  content: '\e951';
}

.icon-house-fire::before {
  content: '\e952';
}

.icon-house-important::before {
  content: '\e953';
}

.icon-house-money::before {
  content: '\e954';
}

.icon-house-safe::before {
  content: '\e955';
}

.icon-house-water-damage::before {
  content: '\e956';
}

.icon-important-info::before {
  content: '\e957';
}

.icon-info-sheet::before {
  content: '\e959';
}

.icon-injury-plaster::before {
  content: '\e95a';
}

.icon-job-processes::before {
  content: '\e95b';
}

.icon-law::before {
  content: '\e95c';
}

.icon-light-bulb-idea::before {
  content: '\e95d';
}

.icon-locate-position::before {
  content: '\e95e';
}

.icon-lock::before {
  content: '\e95f';
}

.icon-mail::before {
  content: '\e960';
}

.icon-money-banknote::before {
  content: '\e961';
}

.icon-money-exchange::before {
  content: '\e962';
}

.icon-motorcycle::before {
  content: '\e965';
}

.icon-mouse::before {
  content: '\e966';
}

.icon-newspaper::before {
  content: '\e967';
}

.icon-notebook::before {
  content: '\e968';
}

.icon-office-chair::before {
  content: '\e969';
}

.icon-pages-documents::before {
  content: '\e96a';
}

.icon-paper-document::before {
  content: '\e96b';
}

.icon-paper-lock::before {
  content: '\e96c';
}

.icon-partner-handshake::before {
  content: '\e96d';
}

.icon-pen::before {
  content: '\e96e';
}

.icon-pension-rocker-chair::before {
  content: '\e96f';
}

.icon-people-collaboration::before {
  content: '\e970';
}

.icon-people-communication-external::before {
  content: '\e971';
}

.icon-people-communication-internal::before {
  content: '\e972';
}

.icon-people-connect::before {
  content: '\e973';
}

.icon-person::before {
  content: '\e974';
}

.icon-person-heart-people-love::before {
  content: '\e975';
}

.icon-phone-call::before {
  content: '\e976';
}

.icon-plain::before {
  content: '\e977';
}

.icon-plus::before {
  content: '\e978';
}

.icon-plus-minus::before {
  content: '\e979';
}

.icon-present::before {
  content: '\e97a';
}

.icon-puzzle::before {
  content: '\e97b';
}

.icon-puzzle-pieces::before {
  content: '\e97c';
}

.icon-radio::before {
  content: '\e97d';
}

.icon-repair::before {
  content: '\e97e';
}

.icon-rewards::before {
  content: '\e97f';
}

.icon-rocket::before {
  content: '\e980';
}

.icon-save::before {
  content: '\e981';
}

.icon-search::before {
  content: '\e982';
}

.icon-search-document::before {
  content: '\e983';
}

.icon-share-chart::before {
  content: '\e984';
}

.icon-share-graph-arrow-down::before {
  content: '\e985';
}

.icon-share-graph-arrow-up::before {
  content: '\e986';
}

.icon-sharing-connect::before {
  content: '\e987';
}

.icon-shield::before {
  content: '\e988';
}

.icon-shopping-cart::before {
  content: '\e98b';
}

.icon-smartphone::before {
  content: '\e98c';
}

.icon-soccer::before {
  content: '\e98d';
}

.icon-social-media-facebook::before {
  content: '\e98e';
}

.icon-social-media-twitter::before {
  content: '\e98f';
}

.icon-sports::before {
  content: '\e990';
}

.icon-sport-sailing-ship::before {
  content: '\e991';
}

.icon-sports-car::before {
  content: '\e992';
}

.icon-sport-trainer::before {
  content: '\e993';
}

.icon-star-event::before {
  content: '\e994';
}

.icon-stars-sky-galaxy::before {
  content: '\e995';
}

.icon-steering-wheel::before {
  content: '\e996';
}

.icon-stock-data-graph::before {
  content: '\e997';
}

.icon-stove-cooker::before {
  content: '\e998';
}

.icon-strategy::before {
  content: '\e999';
}

.icon-student-school-graduation::before {
  content: '\e99a';
}

.icon-survey::before {
  content: '\e99b';
}

.icon-suv-car::before {
  content: '\e99c';
}

.icon-tablet-ipad-apps::before {
  content: '\e99d';
}

.icon-target::before {
  content: '\e99e';
}

.icon-thief::before {
  content: '\e99f';
}

.icon-to-do-list::before {
  content: '\e9a0';
}

.icon-tooth::before {
  content: '\e9a1';
}

.icon-tv::before {
  content: '\e9a2';
}

.icon-umbrella-protection::before {
  content: '\e9a3';
}

.icon-video-camera::before {
  content: '\e9a4';
}

.icon-wall-balance::before {
  content: '\e9a5';
}

.icon-washer::before {
  content: '\e9a6';
}

.icon-watering-can::before {
  content: '\e9a7';
}

.icon-water-tap::before {
  content: '\e9a8';
}

.icon-wheelchair::before {
  content: '\e9a9';
}

.icon-world-globe::before {
  content: '\e9aa';
}

.icon-world-wide-we::before {
  content: '\e9ab';
}

.icon-eh-small::before {
  content: '\e9c0';
}

.icon-left-open-big::before {
  content: '\e800';
}
/* '' */
.icon-right-open-big::before {
  content: '\e801';
}
/* '' */
.icon-plus::before {
  content: '\e802';
}
/* '' */
.icon-minus::before {
  content: '\e803';
}
/* '' */
.icon-check::before {
  content: '\e804';
}
/* '' */
.icon-sort-down::before {
  content: '\e805';
}
/* '' */
.icon-info-circled::before {
  content: '\e806';
}
/* '' */
.icon-sort::before {
  content: '\e807';
}
/* '' */
.icon-sort-up::before {
  content: '\e808';
}
/* '' */
.icon-cross::before {
  content: '\e809';
}
/* '' */

@for $i from 0 through 9 {
  .icon-char-#{$i}::before {
    content: '#{$i}';
    font-family: sans-serif;
  }
}

$letters: A, B, C, D, E, F, G;
@each $letter in $letters {
  .icon-char-#{$letter}::before {
    content: '#{$letter}';
    font-family: sans-serif;
  }
}
