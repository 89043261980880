.btn {
  border: 1px solid;
  border-radius: 5%;

  &--content > * {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    cursor: pointer;
  }

  &.smallest {
    border-radius: 0.15rem;
    font-size: 0.6rem;
    padding-bottom: 0.25rem;
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    padding-top: 0.25rem;
  }

  &.smaller {
    border-radius: 0.175rem;
    font-size: 0.65rem;
    padding-bottom: 0.3rem;
    padding-left: 0.65rem;
    padding-right: 0.65rem;
    padding-top: 0.3rem;
  }

  &.small {
    border-radius: 0.2rem;
    font-size: 0.8rem;
    padding-bottom: 0.35rem;
    padding-left: 0.725rem;
    padding-right: 0.725rem;
    padding-top: 0.35rem;
  }

  &.normal {
    border-radius: 0.25rem;
    font-size: 0.85rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.375rem;
  }

  &.large {
    border-radius: 0.3rem;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
  }

  &.larger {
    border-radius: 0.4rem;
    font-size: 1.25rem;
    padding-bottom: 0.7rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.7rem;
  }

  &.largest {
    border-radius: 0.5rem;
    font-size: 1.5rem;
    padding-bottom: 0.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.8rem;
  }

  &.none {
    border: none;
  }

  &.outline {
    border-style: solid;
    border-width: 1px;
  }

  &.solid {
  }
}
