.lmr-layout-frame {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
}

.lmr-layout-frame span {
  flex: 1;
}

.lmr-layout-left {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.lmr-layout-middle {
  text-align: center;
}

.lmr-layout-right {
  text-align: right;
}

.lmr-layout-child {
  margin-right: 0.5rem;
}
