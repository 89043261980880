@import './theme/Colors';
@import './theme/Fonts';

/*
 * Colors
 */

$transparent-color: transparent;
$canvas-color: $light;
$text-color: $deep-gray;
$highlight-text-color: $secondary-energictone23;
$inverted-text-color: $light;
$divider-color: #bfbfbf;
$primary-color: $primary-red;
$secondary-color: $primary-blue;
$disabled-color: $medium-gray;
$warning-color: $secondary-energictone10;
$invalid-color: $secondary-energictone9;
$placeholder-color: $quote-text-color;
$dimmed-color: $light-gray;
$dark-color: #4d4d4d;
$backdrop-color: $shadow-tooltip-color;
$brand-primary-color: #006192;
$brand-accent-color: #ccdd61;
//$brand-primary-color: magenta;
//$brand-accent-color: chartreuse;

/*
 * Fonts
 */

$base-font-family-applied: 'Applied', 'Arial Black', 'Impact', 'Arial', 'Verdana', 'sans-serif';

$base-font-family-regular: 'AllianzNeoRegular', 'Arial', 'Verdana', 'sans-serif';
$base-font-family-regular-bold: 'AllianzNeoRegularBold', 'Arial', 'Verdana', 'sans-serif';
$base-font-family-regular-italic: 'AllianzNeoRegularItalic', 'Arial', 'Verdana', 'sans-serif';
$base-font-family-regular-bold-italic: 'AllianzNeoRegularBoldItalic', 'Arial', 'Verdana', 'sans-serif';

$base-font-family-light: 'AllianzNeoLight', 'Arial', 'Verdana', 'sans-serif';
$base-font-family-light-bold: 'AllianzNeoLightBold', 'Arial', 'Verdana', 'sans-serif';
$base-font-family-light-italic: 'AllianzNeoLightItalic', 'Arial', 'Verdana' 'sans-serif';
$base-font-family-light-bold-italic: 'AllianzNeoLightBoldItalic', 'Arial', 'Verdana', 'sans-serif';

$base-font-size: 16;
$base-font-family: $base-font-family-light;
$base-font-weight: 300;
$base-line-height: 22;

.font-family-regular {
  font-family: $base-font-family-regular;
}

.font-family-bold {
  font-family: $base-font-family-regular-bold;
}

.font-family-italic {
  font-family: $base-font-family-regular-italic;
}

.font-family-bold-italic {
  font-family: $base-font-family-regular-bold-italic;
}
