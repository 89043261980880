@import '../../style/AppStyles';

.icon-container {
  display: inline-block;
  text-align: center;
  vertical-align: middle;

  &.icon-smallest {
    line-height: 0.5rem;
    width: 0.5rem;
  }

  &.icon-smaller {
    line-height: 0.7rem;
    width: 0.7rem;
  }

  &.icon-small {
    line-height: 1rem;
    width: 1rem;
  }

  &.icon-normal {
    line-height: 1.7rem;
    width: 1.7rem;
  }

  &.icon-large {
    line-height: 2rem;
    width: 2rem;
  }

  &.icon-larger {
    line-height: 3rem;
    width: 3rem;
  }

  &.icon-largest {
    line-height: 4rem;
    width: 4rem;
  }

  &.outline {
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
  }

  &.solid {
    border-radius: 50%;
  }
}
