@import '../Mixins';

// beware where this sass file is compiled in css to know the real path font for css-loader
$path-to-font-directory: './theme/fonts';

// Applied
@include font-face-woff($path-to-font-directory, Applied, 'AppliedSansPro-CnUltraBlack');

// Regular
@include font-face-woff($path-to-font-directory, AllianzNeoRegular, 'AllianzNeoW04-Regular');
@include font-face-woff($path-to-font-directory, AllianzNeoRegularBold, 'AllianzNeoW04-Bold');
@include font-face-woff($path-to-font-directory, AllianzNeoRegularItalic, 'AllianzNeoW04-Italic');
@include font-face-woff($path-to-font-directory, AllianzNeoRegularBoldItalic, 'AllianzNeoW04-BoldItalic');

// Light
@include font-face-woff($path-to-font-directory, AllianzNeoLight, 'AllianzNeoW04-Light');
@include font-face-woff($path-to-font-directory, AllianzNeoLightBold, 'AllianzNeoW04-SemiBold');
@include font-face-woff($path-to-font-directory, AllianzNeoLightItalic, 'AllianzNeoW04-LightItalic');
@include font-face-woff($path-to-font-directory, AllianzNeoLightBoldItalic, 'AllianzNeoW04-SemiBoldIt');
