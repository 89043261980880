$small-width: 350px;
$medium-width: 500px;
$large-width: 960px;

.modal {
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  &-container {
    align-self: center;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 2px 0 #333, 0 4px 12px 0 #333,
      0 8px 21px 0 #333;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding: 2rem;
    position: relative;
  }

  &-content {
    &-small {
      max-width: $small-width;
    }

    &-medium {
      max-width: $medium-width;
    }

    &-large {
      max-width: $large-width;
    }
  }
}

.float-right {
  float: right;
}
