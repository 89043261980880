$breakpoints: (
  small: 'small',
  not-small: 'not-small',
  medium: 'medium',
  large: 'large'
);

/*
 * @author https://github.com/HugoGiraudel
 */
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

/*
 * FONT
 */
@mixin font-face($pathToFontDirectory, $fontFamily, $filename, $weight: normal, $style: normal) {
  @font-face {
    font-family: $fontFamily;
    font-style: $style;
    src: url('#{$pathToFontDirectory}/eot/#{$filename}.eot');
    src: url('#{$pathToFontDirectory}/eot/#{$filename}.eot?#iefix') format('embedded-opentype'),
      url('#{$pathToFontDirectory}/woff2/#{$filename}.woff2') format('woff2'),
      url('#{$pathToFontDirectory}/woff/#{$filename}.woff') format('woff'),
      url('#{$pathToFontDirectory}/ttf/#{$filename}.ttf') format('truetype'),
      url('#{$pathToFontDirectory}/svg/#{$filename}.svg##{$fontFamily}') format('svg');
  }
}
@mixin font-face-woff($pathToFontDirectory, $fontFamily, $filename, $weight: normal, $style: normal) {
  @font-face {
    font-family: $fontFamily;
    font-style: $style;
    src: url('#{$pathToFontDirectory}/woff/#{$filename}.woff') format('woff');
  }
}

@mixin font-face-ttf($pathToFontDirectory, $fontFamily, $filename, $weight: normal, $style: normal) {
  @font-face {
    font-family: $fontFamily;
    font-style: $style;
    src: url('#{$pathToFontDirectory}/ttf/#{$filename}.ttf');
  }
}

@mixin font($pixels) {
  font-size: $pixels + px;
  font-size: ($pixels / $base-font-size) + rem;
}

@mixin leading($pixels) {
  line-height: $pixels + px;
  line-height: ($pixels / $base-font-size) + rem;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include font($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @include break($fs-breakpoint) {
        @include font($fs-font-size);
      }
    }
  }
}

@mixin font-leading($fl-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fl-font-size in $fl-map {
    @if $fs-breakpoint == null {
      @include leading($fl-font-size);
    } @else {
      // If $fl-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @include break($fs-breakpoint) {
        @include leading($fl-font-size);
      }
    }
  }
}

/** Set input styles **/
@mixin form-field--input($primaryColor, $secondaryColor, $borderColor) {
  background-color: $secondaryColor;
  border-bottom: solid $borderColor 1px;
  color: $primaryColor;
  font-weight: bold;
}

@mixin shared-input($primaryColor, $bold: null) {
  @if $bold == null {
    font-family: $base-font-family-light;
  } @else {
    font-family: $base-font-family-light-bold;
  }

  &:input-placeholder {
    color: $primaryColor;
    font-family: $base-font-family-light;
  }

  &::placeholder {
    color: $primaryColor;
    font-family: $base-font-family-light;
  }

  &:invalid {
    box-shadow: none;
  }

  &.invalid {
    box-shadow: 0;
    color: $secondary-energictone9;
  }

  &:focus {
    outline-width: 0;
  }
}

/*
 * Shadow box
 */
@mixin shadow-box-light() {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin shadow-box($color) {
  border: solid $color 1px;
  box-shadow: 0 4px 10px 0 $shadow-tooltip-color;
}

@mixin shadow-messagebox($important: null, $position: null) {
  @if $position == 'top' {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.18) $important;
  } @else {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.16), 0 3px 8px 0 rgba(0, 0, 0, 0.18),
      0 8px 10px 0 rgba(0, 0, 0, 0.08) $important;
  }
}

/*
 * Focus on input
 */
@mixin focus-input() {
  border-color: hsl(216, 94%, 73%);
  box-shadow: 0 0 5px 3px hsl(216, 94%, 73%);
}

/*
 * BUTTON
 */
@mixin btn-hover($color) {
  background-color: darken($color, 10%);
  border-color: darken($color, 10%);
}

@mixin btn-active($color) {
  background-color: darken($color, 15%);
  border-color: darken($color, 15%);
}

@mixin btn-disabled($color, $borderColor, $backgroundColor) {
  background-color: $backgroundColor;
  border-color: $borderColor;
  color: $color;
}

@mixin keyframe($animation-name) {
  @keyframes #{ $animation-name } {
    @content;
  }
}

@mixin animation($animation, $duration, $transition, $iteration) {
  animation-duration: $duration;
  animation-iteration-count: $iteration;
  animation-name: $animation;
  animation-timing-function: $transition;
}

/*
 * Returns the opposite direction of each direction in a list
 * @author Hugo Giraudel
 * @param {List} $directions - List of initial directions
 * @return {List} - List of opposite directions
*/
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
    }
  }

  @return $opposite-directions;
}

/*
 * Triangle helper mixin
 * @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
 * @param {Color} $textColor [currentcolor] - Triangle textColor
 * @param {Length} $size [1em] - Triangle size
*/
@mixin triangle($direction, $color: $light, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error 'Direction must be either `top`, `right`, `bottom` or `left`.';
  }

  $perpendicular-borders: $size solid transparent;
  $opposite: opposite-direction($direction);
  $border-opposite: 'border-#{$opposite}';

  content: '';
  height: 0;
  width: 0;
  z-index: 2;
  #{$border-opposite}: ($size * 1.5) solid $color;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

/*
 * Gradient helper mixin
 * @param {Color} $textColor [currentcolor] - gradient textColor
 * @param {Direction} $orientation [0deg] - gradient orientation
*/
@mixin gradient($color, $orientation: 0deg) {
  background: linear-gradient($orientation, rgba($color, 0.4) 0%, rgba($color, 0.2) 30%, rgba($color, 0.1) 100%);
}

@mixin theme-grade-detail($color) {
  background-color: rgba($color, 0.1);
  color: $color;
}

@mixin square-size($width) {
  height: $width;
  max-height: $width;
  max-width: $width;
  min-height: $width;
  min-width: $width;
}
